import { Box, Paper } from '@mui/material'
import 'react-piano/dist/styles.css'
import { useOsmdContext } from '../contexts/OsmdContext'

export default function OpenSheetMusicDisplay() {

  const { osmdRef } = useOsmdContext()

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      sx={{ marginLeft: '12.5%', marginRight: '12.5%', paddingBottom: '182px', paddingTop: '150px' }}>
      <Paper elevation={3} >
        <div ref={osmdRef} />
      </Paper>
    </Box>
  )
}