import { useMIDIInput } from '../contexts/MIDIInputContext'
import { useMusicPlayback } from '../contexts/MusicPlaybackContext'
import { OpenSheetMusicDisplay as OSMD } from 'opensheetmusicdisplay'
import React, { useState, useContext, useEffect, useRef, RefObject } from 'react'
import { useAudioPlayer } from './AudioOutputContext'

interface IOsmdContext {
    osmd: OSMD | null,
    setOsmd: (osmd: OSMD) => void,
    osmdRef: RefObject<HTMLDivElement>,
    file: string,
    setFile: (file: string) => void,
    title: string,
    isLoading: boolean,
    cursorDisabled: boolean,
    toggleCursor: () => void,
    maxMeasures: number,
    currentMeasure: number,
    setCurrentMeasure: (measure: number) => void,
    moveCursorToMeasureIndex: (index: number) => void,
    advanceCursor: () => void,
    resetCursor: () => void,
    goToNextMeasure: () => void,
    onPlayPressed: () => void,
    onStopPressed: () => void
}

const OsmdContext = React.createContext({} as IOsmdContext)

export function useOsmdContext() {
    return useContext(OsmdContext)
}

interface IOsmdProviderProps {
    children: JSX.Element | JSX.Element[] 
}

export default function OsmdProvider({ children }: IOsmdProviderProps) {

    const [osmd, setOsmd] = useState<OSMD | null>(null)
    const [title, setTitle] = useState("")
    const [cursorDisabled, setCursorDisabled] = useState(true)
    const [maxMeasures, setMaxMeasures] = useState<number>(0)
    const [currentMeasure, setCurrentMeasure] = useState<number>(0)
    const [file, setFile] = useState("AllCreaturesOfOurGodAndKing.xml")
    const [isLoading, setIsLoading] = useState(false)

    const { audioPlayer } = useAudioPlayer()
    const { noteToPlay, isPlayingSong, playSong, stopSong, setBeatsPerMinute } = useMusicPlayback()
    const { lastNoteEvent } = useMIDIInput()

    const osmdRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (osmd === null) {
          const options = {
            autoResize: true,
            drawTitle: true,
          }
          setOsmd(new OSMD(osmdRef.current!, options))
        }
      }, [])
    
      useEffect(() => {
        if (osmd !== null) {
          loadFile()
        }
      }, [osmd])
    
      useEffect(() => {
        if (lastNoteEvent === null || lastNoteEvent == undefined) {
          return
        }
    
        if (lastNoteEvent.message.type === "noteon") {
          audioPlayer.noteOn(lastNoteEvent.note)
        } else if (lastNoteEvent.message.type === "noteoff") {
          audioPlayer.noteOff(lastNoteEvent.note)
        }
      }, [lastNoteEvent])
    
      useEffect(() => {
        if (noteToPlay === null) {
          return
        }
    
        scrollToCursor()
        if (osmd?.cursor.Iterator.CurrentMeasureIndex !== undefined) {
          setCurrentMeasure(osmd?.cursor.Iterator.CurrentMeasureIndex)
        }
      }, [noteToPlay])

    useEffect(() => {
        loadFile()
      }, [file])
    
      function loadFile() {
        stopSong()
        if (file === null) {
          return
        }
        setIsLoading(true)
        osmd?.load(file!.toString()).then(() => {
          setMaxMeasures(osmd.Sheet.LastMeasureNumber)
          if (osmd.Sheet.HasBPMInfo) {
            setBeatsPerMinute(osmd.Sheet.SourceMeasures[0].TempoInBPM)
          }
          osmd.render()
          setTitle(osmd.Sheet.Title.text)
          setIsLoading(false)
          resetCursor()
          setCursorDisabled(true)
        })
      }
    
      function toggleCursor() {
        showCursor(cursorDisabled)
      }
    
      function showCursor(isShown: boolean) {
        if (isShown) {
          osmd?.cursor.show()
          setCursorDisabled(false)
        } else {
          osmd?.cursor.hide()
          setCursorDisabled(true)
        }
      }
    
      function onNextMeasurePressed() {
        let currentMeasure = osmd?.cursor.Iterator.CurrentMeasureIndex
        if (currentMeasure !== undefined && currentMeasure < maxMeasures) {
          moveCursorToMeasureIndex(currentMeasure + 1)
        }
      }
    
      function onPlayPressed() {
        if (osmd !== null && osmd !== undefined) {
          if (!isPlayingSong) {
            showCursor(true)
            playSong(osmd, handleSongEnded)
          } else {
            stopSong()
          }
        }
      }
    
      function onStopPressed() {
        stopSong()
        resetCursor()
        showCursor(false)
      }
    
      function advanceCursor() {
        // Calls the API cursor.next function and helps keep track of our current measure.
        // Call advanceCursor() instead of osmd?.cursor.next()
        osmd?.cursor.next()
        if (osmd?.cursor.Iterator.CurrentMeasureIndex != undefined) {
          setCurrentMeasure(osmd?.cursor.Iterator.CurrentMeasureIndex)
        }
        scrollToCursor()
      }
    
      function resetCursor() {
        stopSong()
        setCurrentMeasure(0)
        osmd?.cursor.reset()
        scrollToCursor()
      }
    
      function moveCursorToMeasureIndex(index: number) {
        if (osmd === null) {
            return;
        }
        let cursor = osmd.cursor
        if (cursor === null || cursor === undefined) {
          return
        }
        if (cursor.iterator.CurrentMeasureIndex >= index) {
          cursor.reset()
        }
        while (cursor.iterator.CurrentMeasureIndex < index) {
          cursor.next()
          scrollToCursor()
        }
    
        if (cursor.Iterator.CurrentMeasureIndex !== undefined) {
          setCurrentMeasure(osmd?.cursor.Iterator.CurrentMeasureIndex)
        }

        if (isPlayingSong) {
            playSong(osmd, handleSongEnded)
        }
      }
    
      function scrollToCursor() {
        //Scrolls the page to the cursor
        if (osmd?.cursor.cursorElementId === undefined) { return }
        const cursorLocation = document.getElementById(osmd?.cursor.cursorElementId)
        if (cursorLocation != null) {
          cursorLocation.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }

      function handleSongEnded() {
        resetCursor()
        showCursor(false)
      }

    const value: IOsmdContext = {
        osmd: osmd,
        setOsmd: setOsmd,
        osmdRef: osmdRef,
        file: file,
        setFile: setFile,
        title: title,
        isLoading: isLoading,
        cursorDisabled: cursorDisabled,
        toggleCursor: toggleCursor,
        maxMeasures: maxMeasures,
        currentMeasure: currentMeasure,
        setCurrentMeasure: setCurrentMeasure,
        moveCursorToMeasureIndex: moveCursorToMeasureIndex,
        advanceCursor: advanceCursor,
        resetCursor: resetCursor,
        goToNextMeasure: onNextMeasurePressed,
        onPlayPressed: onPlayPressed,
        onStopPressed: onStopPressed
    }

    return (
        <OsmdContext.Provider value={value}>
            {children}
        </OsmdContext.Provider>
    )
}

