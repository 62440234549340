import React, { useState, useEffect, useContext } from 'react'
import { WebMidi, NoteMessageEvent, Note, Input, Output } from 'webmidi'

interface IMidiInputContext {
    lastNoteEvent: NoteMessageEvent | null
    activeNotes: Note[]
    availableInputs: Input[]
    currentInput: Input | null
    changeCurrentInput: (input: Input) => void
    midiPlayer: MIDIPlayer
}

interface IMIDIInputProviderProps {
    children: JSX.Element | JSX.Element[] 
}

const MIDIInputContext = React.createContext({} as IMidiInputContext)

export function useMIDIInput() {
    return useContext(MIDIInputContext)
}

class MIDIPlayer {
    public currentOutput: Output | null = null

    public notesOnWithNumberDuration(numbers: number[], durationsSeconds: number, delay: number) {
        if (this.currentOutput === null) {
            return
        }
        this.currentOutput.playNote(numbers, {channels:[1], duration:durationsSeconds, time:WebMidi.time+delay})
    }
}

export default function MIDIInputProvider({ children }: IMIDIInputProviderProps) {
    const [lastNoteEvent, setLastNoteEvent] = useState<NoteMessageEvent | null>(null)
    const [activeNotes, setActiveNotes] = useState<Note[]>([])
    const [availableInputs, setAvailableInputs] = useState<Input[]>([])
    const [currentInput, setCurrentInput] = useState<Input | null>(null)
    const [availableOutputs, setAvailableOutputs] = useState<Output[]>([])
    const [currentOutput, setCurrentOutput] = useState<Output | null>(null)
    const [midiPlayer, setMidiPlayer] = useState(new MIDIPlayer())

    useEffect(() => {
        WebMidi.enable()
            .then(() => {
                console.log("WebMidi enabled!")
                setAvailableInputs(WebMidi.inputs)
                if (WebMidi.inputs.length > 0) {
                    setCurrentInput(WebMidi.inputs[0])
                }
                setAvailableOutputs(WebMidi.outputs)
                if (WebMidi.inputs.length > 0) {
                    setCurrentOutput(WebMidi.outputs[0])
                }
            })
            .catch(err => console.error("Failed to enable WebMidi " + err))
    }, [])

    useEffect(() => {
        setMidiPlayer(current => {
            current.currentOutput = currentOutput
            return current
        })
    }, [currentOutput])

    useEffect(() => {
        if (currentInput === null || currentInput === undefined) {
            return
        }
        currentInput.removeListener()
        currentInput.addListener("noteon", event => {
            setLastNoteEvent(event)
            setActiveNotes(current => [...current, event.note])
            console.log(event.note.identifier + " on")
        })
        currentInput.addListener("noteoff", event => {
            setLastNoteEvent(event)
            setActiveNotes(current => current.filter(note => note.identifier !== event.note.identifier))
            console.log(event.note.identifier + " off")
        })
    }, [currentInput])

    useEffect(() => {
        currentOutput?.playNote(["C4"],{channels:[1], duration:1000, time:WebMidi.time+1000})
    }, [currentOutput])

    useEffect(() => {
        console.log(activeNotes)
    }, [activeNotes])

    function changeCurrentInput(input: Input) {
        currentInput?.removeListener()
        setCurrentInput(input)
    }

    const value: IMidiInputContext = {
        lastNoteEvent: lastNoteEvent,
        activeNotes: activeNotes,
        availableInputs: availableInputs,
        currentInput: currentInput,
        changeCurrentInput: changeCurrentInput,
        midiPlayer: midiPlayer
    }

    return (
        <MIDIInputContext.Provider value={value}>
            {children}
        </MIDIInputContext.Provider>
    )
}