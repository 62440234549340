import { Cursor, OpenSheetMusicDisplay } from 'opensheetmusicdisplay'
import React, { useState, useContext, useEffect, useRef } from 'react'
import { useAudioPlayer } from '../contexts/AudioOutputContext'
import { performAfterDelay, waitMillis } from '../util/AsyncUtil'
import { useMIDIInput } from '../contexts/MIDIInputContext'

export class NoteTiming {
    constructor(
      public timestamp: number,
      public note: number,
      public length: number) { }
}

interface IMusicPlaybackContext {
    noteToPlay: NoteTiming | null
    playbackNotes: NoteTiming[]
    beatsPerMinute: number
    isPlayingSong: boolean
    playSong: (osmd: OpenSheetMusicDisplay, onSongEnd: () => void) => void
    stopSong: () => void
    pauseSong: () => void
    setBeatsPerMinute: (bpm: number) => void
}

interface IMusicPlaybackProviderProps {
    children: JSX.Element | JSX.Element[] 
}

const MusicPlaybackContext = React.createContext({} as IMusicPlaybackContext)

export function useMusicPlayback() {
    return useContext(MusicPlaybackContext)
}

export default function MusicPlaybackProvider({ children }: IMusicPlaybackProviderProps) {
    const [playbackNotes, setPlaybackNotes] = useState<NoteTiming[]>([])
    const [noteToPlay, setNoteToPlay] = useState<NoteTiming | null>(null)
    const [beatsPerMinute, setBeatsPerMinute] = useState(200)
    const [isPlayingSong, setIsPlayingSong] = useState(false)
    const playingTimer = useRef<NodeJS.Timer | null>(null)
    const [pauseTimestamp, setPauseTimestamp] = useState(0)

    const { audioPlayer } = useAudioPlayer()
    const { midiPlayer } = useMIDIInput()

    function playSong(osmd: OpenSheetMusicDisplay, onSongEnd: () => void) {
        if (isPlayingSong) {
            stopSong()
        }
        setIsPlayingSong(true)
        var allNotes: NoteTiming[] = []
        var cursorStartTimestamp = osmd.cursor.Iterator.currentTimeStamp.RealValue
        osmd.cursor.reset()
        var iterator = osmd?.cursor.Iterator

        if (iterator === null || iterator === undefined) {
            return
        }

        var noteIndex = -1
        while(!iterator.EndReached) {
            const voices = iterator.CurrentVoiceEntries
            for(var i = 0; i < voices.length; i++) {
                const v = voices[i]
                const notes = v.Notes
                for(var j = 0; j < notes.length; j++) {
                      const note = notes[j]
                      // Make sure our note is not silent
                      if(note != null && note.halfTone != 0 && !note.isRest()) {
                        var length = note.Length.RealValue
                        if (note.NoteTie !== undefined) {
                            if (note.NoteTie.StartNote == note) {
                                length = note.NoteTie.Notes.reduce((partialSum, n) => partialSum + n.Length.RealValue, 0)
                            } else {
                                continue // This note is part of a tie, only count the first note
                            }
                        }
                        allNotes.push(new NoteTiming(iterator.currentTimeStamp.RealValue, note.halfTone !== null ? note.halfTone + 12 : -1, length))
                        if (iterator.currentTimeStamp.RealValue < cursorStartTimestamp) {
                            noteIndex = allNotes.length - 1
                        }
                      }
                }
              }
              iterator.moveToNext()
          }

          // Move cursor back to where it started
          osmd?.cursor.reset()
          while (osmd.cursor.Iterator.currentTimeStamp.RealValue < cursorStartTimestamp) {
            osmd.cursor.next()
          }

          if (iterator === null || iterator === undefined) {
            return
          }
          var cursor = osmd?.cursor
          if (cursor === null || iterator === undefined) {
            return
          }
          
          var startTime = Date.now()
          const lookAheadMillis = 150
          const intervalMillis = 30

          // If the user attempts to play a song after all notes (e.g. rest measures at the end), end the song immediately.
          if (noteIndex + 1 >= allNotes.length) {
            endSongAfterDelay(onSongEnd)
            return
          }

          var interval = setInterval(() => {
            let currentTime = Date.now()
            let currentTimestamp = currentTime - startTime + cursorStartTimestamp

            if (noteIndex + 1 >= allNotes.length) {
                return
            }
            let nextNote = allNotes[noteIndex + 1]
            let waitTimeStamp = timestampToActualMillis(nextNote.timestamp - cursorStartTimestamp)
            if (currentTimestamp >= waitTimeStamp - lookAheadMillis) {
                var notesToPlay = [nextNote]

                var delay = Math.max(waitTimeStamp - currentTimestamp, 0)

                advanceCursor(allNotes[noteIndex + 1], cursor, delay)

                noteIndex += 1

                if (noteIndex < allNotes.length - 1) {
                    // We want to batch multiple notes together if they happen at the same time.
                    // Keep advancing to the next note if the timestamps are the same.
                    while (allNotes[noteIndex + 1].timestamp === nextNote.timestamp) {
                        notesToPlay = [...notesToPlay, allNotes[noteIndex + 1]]
                        noteIndex += 1
                        if (noteIndex >= allNotes.length - 1) {
                            break
                        }
                    }
                }

                playNotes(notesToPlay, delay / 1000)
            }

            if (noteIndex >= allNotes.length - 1) {
                endSongAfterDelay(onSongEnd)
            }
          }, intervalMillis)
          playingTimer.current = interval
    }

    // For now, add a delay before calling onSongEnd(). This gives a bit of time for the last few notes to be played.
    async function endSongAfterDelay(onSongEnd: () => void) {
        await waitMillis(2000)
        stopSong() // Will end the interval
        onSongEnd()
    }

    function playNotes(notes: NoteTiming[], delay: number) {
        let durations = notes.map(n => (60 / beatsPerMinute) * n.length * 4)
        audioPlayer.notesOnWithNumberDuration(notes.map(n => n.note), durations, delay)
        midiPlayer.notesOnWithNumberDuration(notes.map(n => n.note), durations[0]*1000, delay)
        performAfterDelay(delay, () => {
            setPlaybackNotes(current => current.concat(notes))
            notes.forEach(note => {
                setNoteToPlay(note)
                removePlaybackNoteWhenComplete(note)
            })
        })
    }

    async function advanceCursor(note: NoteTiming, cursor: Cursor, delayMillis: number) {
        await waitMillis(delayMillis)
        if (playingTimer.current === null) {
            return
        }
        while (cursor.Iterator.currentTimeStamp.RealValue < note.timestamp) {
            cursor.next()
        }
    }

    function removePlaybackNoteWhenComplete(note: NoteTiming) {
        // Remove the note slightly early so that when the same note is played twice in a row with no rests, it will look like it was pressed twice.
        const millisBetweenNotes = 50 
        new Promise(res=>setTimeout(res, timestampToActualMillis(note.length) - millisBetweenNotes)).then( () => {
            const timestamp = note.timestamp
            setPlaybackNotes(current => current.filter(item => 
                item.timestamp !== timestamp || item.note !== item.note))
        })
    }

    function timestampToActualMillis(timestamp: number) {
        // Assuming 4/4 time
        return timestamp * 4000 * (60 / beatsPerMinute)
    }

    function stopSong() {
        if (playingTimer.current !== null) {
            setPlaybackNotes([])
            clearInterval(playingTimer.current)
            playingTimer.current = null
        }
        setIsPlayingSong(false)
    }

    function pauseSong() {
        if (playingTimer.current !== null) {
            setPlaybackNotes([])
            clearInterval(playingTimer.current)
            playingTimer.current = null
        }
        setIsPlayingSong(false)
    }

    const value: IMusicPlaybackContext = {
        noteToPlay: noteToPlay,
        playbackNotes: playbackNotes,
        beatsPerMinute: beatsPerMinute,
        isPlayingSong: isPlayingSong,
        playSong: playSong,
        stopSong: stopSong,
        pauseSong: pauseSong,
        setBeatsPerMinute
    }

    return (
        <MusicPlaybackContext.Provider value={value}>
            {children}
        </MusicPlaybackContext.Provider>
    )
}