import './../App.css';
import Home from './Home';
import MIDIInputProvider from '../contexts/MIDIInputContext'
import AudioOutputProvider from '../contexts/AudioOutputContext'
import MusicPlaybackProvider from '../contexts/MusicPlaybackContext'
import OsmdProvider from '../contexts/OsmdContext';

function App() {
  return (
    <MIDIInputProvider>
      <AudioOutputProvider>
        <MusicPlaybackProvider>
          <OsmdProvider>
            <Home />
          </OsmdProvider>
        </MusicPlaybackProvider>
      </AudioOutputProvider>
    </MIDIInputProvider>
  );
}

export default App;
