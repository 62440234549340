import { Box } from '@mui/material'
import OpenSheetMusicDisplay from './OpenSheetMusicDisplay'
import { useEffect, useState } from 'react'
import DragFileInfoBox from './DragFileInfoBox'
import { useOsmdContext } from '../contexts/OsmdContext'
import MusicToolbar from './MusicToolbar'
import PianoBar from './PianoBar'
import LoadingSpinner from './LoadingSpinner'

export default function Home() {
    const [isDraggingFile, setIsDraggingFile] = useState<Boolean>(false)

    const { setFile, isLoading } = useOsmdContext()

    useEffect(() => {
        let counter = 0;

        const handleDragEnter = (event: DragEvent) => {
            counter++
            if (counter === 1) {
                event.preventDefault()
                setIsDraggingFile(true)
            }
        };

        const handleDragLeave = (event: DragEvent) => {
            counter--
            if (counter === 0) {
                event.preventDefault()
                setIsDraggingFile(false)
            }
        }

        const handleDragOver = (event: DragEvent) => {
            event.preventDefault();
        };

        const handleDrop = (event: DragEvent) => {
            event.preventDefault();
            counter = 0
            setIsDraggingFile(false)

            if (!event.dataTransfer || !event.dataTransfer.files || event.dataTransfer.files.length === 0) {
                return
            }

            var reader = new FileReader()
            reader.onload = function (res) {
                if (res.target != null) {
                    if (res.target.result != null) {
                        setFile(res.target.result as string)
                    }
                }

            }
            var filename = event.dataTransfer.files[0].name
            if (filename.toLowerCase().indexOf(".xml") > 0
                || filename.toLowerCase().indexOf(".musicxml") > 0) {
                reader.readAsText(event.dataTransfer.files[0])
            } else if (event.dataTransfer.files[0].name.toLowerCase().indexOf(".mxl") > 0) {
                reader.readAsBinaryString(event.dataTransfer.files[0])
            }
            else {
                alert("No vaild .xml/.mxl/.musicxml file!")
            }
        };

        document.addEventListener('dragenter', handleDragEnter);
        document.addEventListener('dragleave', handleDragLeave);
        document.addEventListener('dragover', handleDragOver);
        document.addEventListener('drop', handleDrop);

        return () => {
            document.removeEventListener('dragenter', handleDragEnter);
            document.removeEventListener('dragleave', handleDragLeave);
            document.addEventListener('dragover', handleDragOver);
            document.removeEventListener('drop', handleDrop);
        }
    }, []);


    return (
        <div className="dropzone">
            <Box sx={{ minHeight: "100vh", backgroundColor: "#0a1929" }} >
                <LoadingSpinner canShow={isLoading} />
                <DragFileInfoBox canShow={isDraggingFile} />

                <MusicToolbar />

                <div className={isDraggingFile || isLoading ? "darken" : ""}>
                    <OpenSheetMusicDisplay />
                </div>

                <PianoBar />
            </Box>
        </div>
    )
}