import { useState } from 'react'
import { AppBar, Avatar } from '@mui/material'
import { useAudioPlayer } from '../contexts/AudioOutputContext'
import { useMIDIInput } from '../contexts/MIDIInputContext'
import { Piano, MidiNumbers, KeyboardShortcuts } from 'react-piano'
import 'react-piano/dist/styles.css'
import { useMusicPlayback } from '../contexts/MusicPlaybackContext'
import { Stack } from '@mui/system'
import { Note } from 'webmidi'
import { onlyUnique } from '../util/ListUtil'

export default function PianboBar() {

    const keyboardShortcuts = KeyboardShortcuts.create({
        firstNote: MidiNumbers.fromNote('a0'),
        lastNote: MidiNumbers.fromNote('c8'),
        keyboardConfig: [
            { natural: "A0", flat: "A0♭", sharp: "A0#" },
            { natural: "B0", flat: "B0♭", sharp: "B0#" },
            { natural: "C1", flat: "C1♭", sharp: "C1#" },
            { natural: "D1", flat: "D1♭", sharp: "D1#" },
            { natural: "E1", flat: "E1♭", sharp: "E1#" },
            { natural: "F1", flat: "F1♭", sharp: "F1#" },
            { natural: "G1", flat: "G1♭", sharp: "G1#" },
            { natural: "A1", flat: "A1♭", sharp: "A1#" },
            { natural: "B1", flat: "B1♭", sharp: "B1#" },
            { natural: "C2", flat: "C2♭", sharp: "C2#" },
            { natural: "D2", flat: "D2♭", sharp: "D2#" },
            { natural: "E2", flat: "E2♭", sharp: "E2#" },
            { natural: "F2", flat: "F2♭", sharp: "F2#" },
            { natural: "G2", flat: "G2♭", sharp: "G2#" },
            { natural: "A2", flat: "A2♭", sharp: "A2#" },
            { natural: "B2", flat: "B2♭", sharp: "B2#" },
            { natural: "C3", flat: "C3♭", sharp: "C3#" },
            { natural: "D3", flat: "D3♭", sharp: "D3#" },
            { natural: "E3", flat: "E3♭", sharp: "E3#" },
            { natural: "F3", flat: "F3♭", sharp: "F3#" },
            { natural: "G3", flat: "G3♭", sharp: "G3#" },
            { natural: "A3", flat: "A3♭", sharp: "A3#" },
            { natural: "B3", flat: "B3♭", sharp: "B3#" },
            { natural: "C4", flat: "C4♭", sharp: "C4#" },
            { natural: "D4", flat: "D4♭", sharp: "D4#" },
            { natural: "E4", flat: "E4♭", sharp: "E4#" },
            { natural: "F4", flat: "F4♭", sharp: "F4#" },
            { natural: "G4", flat: "G4♭", sharp: "G4#" },
            { natural: "A4", flat: "A4♭", sharp: "A4#" },
            { natural: "B4", flat: "B4♭", sharp: "B4#" },
            { natural: "C5", flat: "C5♭", sharp: "C5#" },
            { natural: "D5", flat: "D5♭", sharp: "D5#" },
            { natural: "E5", flat: "E5♭", sharp: "E5#" },
            { natural: "F5", flat: "F5♭", sharp: "F5#" },
            { natural: "G5", flat: "G5♭", sharp: "G5#" },
            { natural: "A5", flat: "A5♭", sharp: "A5#" },
            { natural: "B5", flat: "B5♭", sharp: "B5#" },
            { natural: "C6", flat: "C6♭", sharp: "C6#" },
            { natural: "D6", flat: "D6♭", sharp: "D6#" },
            { natural: "E6", flat: "E6♭", sharp: "E6#" },
            { natural: "F6", flat: "F6♭", sharp: "F6#" },
            { natural: "G6", flat: "G6♭", sharp: "G6#" },
            { natural: "A6", flat: "A6♭", sharp: "A6#" },
            { natural: "B6", flat: "B6♭", sharp: "B6#" },
            { natural: "C7", flat: "C7♭", sharp: "C7#" },
            { natural: "D7", flat: "D7♭", sharp: "D7#" },
            { natural: "E7", flat: "E7♭", sharp: "E7#" },
            { natural: "F7", flat: "F7♭", sharp: "F7#" },
            { natural: "G7", flat: "G7♭", sharp: "G7#" },
            { natural: "A7", flat: "A7♭", sharp: "A7#" },
            { natural: "B7", flat: "B7♭", sharp: "B7#" },
            { natural: "C8", flat: "C8♭", sharp: "C8#" },
        ],
    })

    const [currentClickedNotes, setCurrentClickedNotes] = useState<number[]>([])
    const { lastNoteEvent, activeNotes } = useMIDIInput()
    const { audioPlayer } = useAudioPlayer()
    const { playbackNotes, noteToPlay, beatsPerMinute, isPlayingSong, playSong, stopSong, setBeatsPerMinute } = useMusicPlayback()

    const displayedNotes = activeNotes.concat(playbackNotes.map(note =>
        new Note(note.note)
    )).concat(currentClickedNotes.map(note =>
        new Note(note)
    ))
        .map(note => note.identifier)
        .filter(onlyUnique)
        .map(note =>
            <Avatar key={`note-indicator-${note}`} sx={{ bgcolor: "red" }}>{note}</Avatar>
        )


    return <AppBar sx={{ top: 'auto', bottom: 0, width: "100%", background: "transparent" }} elevation={0}>
        <Stack direction="row" spacing={1} sx={{ margin: "8px" }}>
            {displayedNotes}
        </Stack>
        <div style={{ height: "100px", background: "black" }}>
            <Piano
                keyboardShortcuts={keyboardShortcuts}
                noteRange={{ first: MidiNumbers.fromNote('a0'), last: MidiNumbers.fromNote('c8') }}
                playNote={(midiNumber: MidiNumbers) => {
                    let number = midiNumber as unknown as number
                    // Don't play any notes that are already being played by the actual piano
                    if (!activeNotes.some(note => note.number === number) && !playbackNotes.some(note => note.note === number)) {
                        setCurrentClickedNotes(current => [...current, number])
                        audioPlayer.noteOnWithNumber(number)
                    }
                }}
                stopNote={(midiNumber: MidiNumbers) => {
                    let number = midiNumber as unknown as number
                    // Only stop the note if it was played by clicking on the keyboard
                    // since this will trigger when normal piano keys are stopped as well
                    if (currentClickedNotes.contains(number)) {
                        setCurrentClickedNotes(current => current.filter(num => num !== number))
                        audioPlayer.noteOffWithNumber(midiNumber as unknown as number)
                    }
                }}
                activeNotes={activeNotes.map(note => note.number).concat(currentClickedNotes).concat(playbackNotes.map(item => item.note))}>
            </Piano>
        </div>
    </AppBar>
}