import React, { useState, useContext, useEffect } from 'react'
import * as Tone from 'tone'
import { Frequency } from 'tone'
import { Note } from 'webmidi'

interface IAudioOutputContext {
    audioPlayer: AudioPlayer
    volume: number,
    isMuted: Boolean,
    setVolume: (newVolume: number) => void,
    setIsMuted: (newIsMuted: Boolean) => void
}

interface IAudioOutputProviderProps {
    children: JSX.Element | JSX.Element[] 
}

const MIDIAudioContext = React.createContext({} as IAudioOutputContext)

class AudioPlayer {
        private sampler = new Tone.Sampler({
            urls: {
                A0: "A0.mp3",
                C1: "C1.mp3",
                "D#1": "Ds1.mp3",
                "F#1": "Fs1.mp3",
                A1: "A1.mp3",
                C2: "C2.mp3",
                "D#2": "Ds2.mp3",
                "F#2": "Fs2.mp3",
                A2: "A2.mp3",
                C3: "C3.mp3",
                "D#3": "Ds3.mp3",
                "F#3": "Fs3.mp3",
                A3: "A3.mp3",
                C4: "C4.mp3",
                "D#4": "Ds4.mp3",
                "F#4": "Fs4.mp3",
                A4: "A4.mp3",
                C5: "C5.mp3",
                "D#5": "Ds5.mp3",
                "F#5": "Fs5.mp3",
                A5: "A5.mp3",
                C6: "C6.mp3",
                "D#6": "Ds6.mp3",
                "F#6": "Fs6.mp3",
                A6: "A6.mp3",
                C7: "C7.mp3",
                "D#7": "Ds7.mp3",
                "F#7": "Fs7.mp3",
                A7: "A7.mp3",
                C8: "C8.mp3"
            },
            release: 1,
            baseUrl: "https://tonejs.github.io/audio/salamander/"
        }).toDestination()

    constructor() {
        console.log("Starting Tone...")
        Tone.start()
        // Remove this so sounds play immediately. By default they add a delay for "performance and accuracy".
        Tone.context.lookAhead = 0 
    }

    notesOnWithNumberDuration(numbers: number[], durationsSeconds: number[], delay: number) {
        if (!this.sampler.loaded) {
            return;
        }
        let time = Tone.now()
        let frequencies = numbers.map(num => Tone.Frequency(num, "midi").toFrequency())
        this.sampler.triggerAttackRelease(frequencies, durationsSeconds, Tone.now() + delay, 0.75)
    }

    noteOnWithNumberDuration(number: number, durationSeconds: number) {
        if (!this.sampler.loaded) {
            return;
        }
        let frequency = Tone.Frequency(number, "midi").toFrequency()
        this.sampler.triggerAttackRelease(frequency, durationSeconds, Tone.now(), 0.75)
    }

    noteOnWithNumber(number: number) {
        if (!this.sampler.loaded) {
            return;
        }
        let frequency = Tone.Frequency(number, "midi").toFrequency()
        this.sampler.triggerAttack(frequency, Tone.now(), 0.75)
    }

    noteOffWithNumber(number: number) {
        if (!this.sampler.loaded) {
            return;
        }
        let frequency = Tone.Frequency(number, "midi").toFrequency()
        this.sampler.triggerRelease(frequency, Tone.now());
    }

    noteOn(note: Note) {
        if (!this.sampler.loaded) {
            return;
        }
        this.sampler.triggerAttack(note.identifier, Tone.now(), note.rawAttack / 127.0)
    }
    
    noteOff(note: Note) {
        if (!this.sampler.loaded) {
            return;
        }
        this.sampler.triggerRelease(note.identifier, Tone.now());
    }

    setVolumeDecibels(volume: number) {
       if (!this.sampler.loaded) {
            return; 
        }
        this.sampler.volume.value = volume
    }
}

// Declare outside of provider so it's not tied to lifecycle.
// TODO: Investigate better way to do this.
export const currentAudioPlayer = new AudioPlayer()

export function useAudioPlayer() {
    return useContext(MIDIAudioContext)
}

export default function AudioOutputProvider({ children }: IAudioOutputProviderProps) {
    const [audioPlayer] = useState<AudioPlayer>(currentAudioPlayer)
    const [volume, setVolume] = useState<number>(100)
    const [isMuted, setIsMuted] = useState<Boolean>(false)

    useEffect(() => {
        if (!isMuted) {
            setDecibelsFromVolume(volume)
        }
    }, [volume])

    useEffect(() => {
        var targetVolume = isMuted ? -100 : volume
        setDecibelsFromVolume(targetVolume)
    }, [isMuted])

    function setDecibelsFromVolume(newVolume: number) {
        // TODO: Find a way to properly mute rather than decreasing volume.
        var decibels = -(100 - newVolume) * 0.7
        audioPlayer.setVolumeDecibels(decibels)
    }

    const value: IAudioOutputContext = {
        audioPlayer: audioPlayer,
        volume: volume,
        setVolume: setVolume,
        isMuted: isMuted,
        setIsMuted: setIsMuted
    }

    return (
        <MIDIAudioContext.Provider value={value}>
            {children}
        </MIDIAudioContext.Provider>
    )
}