import { Box, Paper, Icon } from '@mui/material'
import FileOpen from '@mui/icons-material/FileOpen'

interface IDragBoxFileInfoBox {
    canShow: Boolean
}

export default function DragFileInfoBox({ canShow }: IDragBoxFileInfoBox) {
    return <> {
        canShow ?
            <Box justifyContent="center" alignItems="center"
                sx={{ zIndex: 'tooltip', position: 'fixed', left: "40%", right: "40%", top: "40%" }}>
                <Paper elevation={3} sx={{ padding: "32px" }}>
                    <Box
                        justifyContent="center"
                        alignItems="center"
                        fontSize="large"
                        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        Drag MusicXML File
                        <Icon fontSize="large">
                            <FileOpen fontSize="large" />
                        </Icon>
                    </Box>
                </Paper>
            </Box>
            : <></>
    }
    </>
}