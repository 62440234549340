import { Box, Paper, Icon } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import './LoadingSpinner.css';

interface ILoadingSpinnerProps {
    canShow: Boolean
}

export default function LoadingSpinner({ canShow }: ILoadingSpinnerProps) {
    return <> {
        canShow ?
            <Box justifyContent="center" alignItems="center"
                sx={{ zIndex: 'tooltip', position: 'fixed', left: "50%", right: "50%", top: "40%" }}>
                <Box>
                    <div className="spin">
                        <Icon fontSize="inherit" sx={{ fontSize: 70, color: 'white', verticalAlign: "middle" }}>
                            <SyncIcon fontSize="inherit" sx={{ verticalAlign: "top" }} />
                        </Icon>
                    </div>
                </Box>
            </Box>
            : <></>
    }
    </>
}