import React from 'react'
import { Button, Slider, Box, Paper, AppBar, Toolbar, Select, MenuItem, SelectChangeEvent, IconButton, Tooltip, FormControl, InputLabel, Divider, Menu, Typography, FormGroup, FormControlLabel, Switch, Theme } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PlayIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import PauseIcon from '@mui/icons-material/Pause'
import TimerIcon from '@mui/icons-material/Timer'
import SettingsIcon from '@mui/icons-material/Settings'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import { useMIDIInput } from '../contexts/MIDIInputContext'
import { useMusicPlayback } from '../contexts/MusicPlaybackContext'
import { useAudioPlayer } from '../contexts/AudioOutputContext'
import { useOsmdContext } from '../contexts/OsmdContext';

class DefaultSong {
    constructor(public file: string, public title: string) { }
 }

const defaultSongs = [
    new DefaultSong("AllCreaturesOfOurGodAndKing.xml", "All Creatures Of Our God And King"),
    new DefaultSong("Beethoven_AnDieFerneGeliebte.xml", "Beethoven: An Die FerneGeliebte"),
    new DefaultSong("Song_of_Storms.musicxml", "Song of Storms"),
    new DefaultSong("Test Repeat.musicxml", "Test Repeat") 
]

export default function MusicToolbar() {

    const [tempoMenuAnchor, setTempoMenuAnchor] = React.useState<null | HTMLElement>(null);
    const tempoMenuOpen = Boolean(tempoMenuAnchor);

    const [settingsMenuAnchor, setSettingsMenuAnchor] = React.useState<null | HTMLElement>(null);
    const settingsMenuOpen = Boolean(settingsMenuAnchor);

    const [volumeMenuAnchor, setVolumeMenuAnchor] = React.useState<null | HTMLElement>(null);
    const volumeMenuOpen = Boolean(volumeMenuAnchor);

    const { availableInputs, currentInput, changeCurrentInput } = useMIDIInput()
    const { isPlayingSong, beatsPerMinute, setBeatsPerMinute } = useMusicPlayback()
    const { volume, setVolume, isMuted, setIsMuted } = useAudioPlayer()
    const { file, setFile, isLoading, title, currentMeasure, maxMeasures, cursorDisabled, moveCursorToMeasureIndex, toggleCursor, resetCursor, goToNextMeasure, onPlayPressed, onStopPressed } = useOsmdContext()

    function onFileSelectionChanged(event: SelectChangeEvent<{ value: unknown }>) {
        setFile(event.target.value as string)
    }

    function onInputChanged(event: SelectChangeEvent<{ value: unknown }>) {
        let newInputId = event.target.value as string
        let input = availableInputs.find(input => input.id === newInputId)
        if (input !== null && input !== undefined) {
            changeCurrentInput(input)
        }
    }

    function onMeasureSliderChange(event: Event, value: number | number[], activeThumb: number) {
        moveCursorToMeasureIndex(value as number)
    }

    function onBeatsPerMinuteChanged(event: Event, value: number | number[], activeThumb: number) {
        setBeatsPerMinute(value as number)
    }

    function onVolumeChanged(event: Event, value: number | number[], activeThumb: number) {
        setVolume(value as number)
    }

    return (
        <AppBar component="nav" sx={{ background: "#262626" }}>
            <Toolbar disableGutters >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        padding: '8px',
                        border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
                        borderRadius: 1,
                        bgcolor: 'info.main',
                        color: 'text.secondary',
                        '& svg': {
                            m: 1.5,
                        },
                        '& hr': {
                            mx: 0.5,
                        },
                    }}>
                    <FormControl>
                        <InputLabel id="select-song-label">Song</InputLabel>
                        <Select
                            id="select-song"
                            displayEmpty
                            value={file as unknown as { value: unknown }}
                            onChange={onFileSelectionChanged}
                            label="Song"
                            style={{
                                width: "300px", marginRight: "16px"
                            }}>
                                {
                                    defaultSongs.map((song, index) => (
                                        <MenuItem key={`select-song-${index}`} value={ song.file }>{ song.title }</MenuItem>
                                    ))
                                }
                                { 
                                    !defaultSongs.some(song => song.file === file) ? <MenuItem key={`select-song-custom`} value={ file }>{ isLoading ? "Loading..." : title }</MenuItem> : null
                                }
                        </Select>
                    </FormControl>

                    <Divider orientation="vertical" variant="middle" flexItem />

                    <Tooltip title="Cursor Enable/Disable">
                        <span>
                            <IconButton onClick={toggleCursor}>
                                {cursorDisabled ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </span>
                    </Tooltip>

                    <Tooltip title="Next Measure">
                        <span>
                            <IconButton onClick={goToNextMeasure} disabled={cursorDisabled}>
                                <KeyboardTabIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Reset Cursor">
                        <span>
                            <IconButton onClick={resetCursor} disabled={cursorDisabled}>
                                <RestartAltIcon />
                            </IconButton>
                        </span>
                    </Tooltip>

                    <Divider orientation="vertical" variant="middle" flexItem />

                    <Tooltip title={!isPlayingSong ? "Play" : "Pause"}>
                        <span>
                            <IconButton onClick={onPlayPressed}>
                                {
                                    !isPlayingSong ? <PlayIcon /> : <PauseIcon />
                                }
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Stop">
                        <span>
                            <IconButton onClick={onStopPressed} disabled={!isPlayingSong}>
                                <StopIcon />
                            </IconButton>
                        </span>
                    </Tooltip>

                    <Tooltip title="Tempo">
                        <span>
                            <Button
                                variant="outlined" endIcon={<TimerIcon />}
                                onClick={e => setTempoMenuAnchor(e.currentTarget)}
                                disabled={isPlayingSong}
                                sx={{
                                    color: 'text.secondary',
                                    borderColor: 'text.secondary',
                                    marginRight: "16px"
                                }}>
                                {beatsPerMinute}
                            </Button>
                        </span>
                    </Tooltip>

                    <Menu
                        id="tempo-menu"
                        anchorEl={tempoMenuAnchor}
                        open={tempoMenuOpen}
                        onClose={e => setTempoMenuAnchor(null)}
                        sx={{ background: "transparent" }}>
                        <Slider
                            area-label="Beats Per Minute"
                            valueLabelDisplay="auto"
                            disabled={isPlayingSong}
                            max={300}
                            min={30}
                            onChange={onBeatsPerMinuteChanged}
                            value={beatsPerMinute}
                            style={{ marginRight: "16px", marginLeft: "16px", marginTop: "32px", marginBottom: "8px", width: "200px", justifyContent: 'center', alignItems: 'center' }} />

                    </Menu>

                    <Tooltip title="Volume">
                        <span>
                            <IconButton onClick={e => setVolumeMenuAnchor(e.currentTarget)}>
                                {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                            </IconButton>
                        </span>
                    </Tooltip>

                    <Divider orientation="vertical" variant="middle" flexItem />

                    <Tooltip title="Settings">
                        <span>
                            <IconButton onClick={e => setSettingsMenuAnchor(e.currentTarget)}>
                                <SettingsIcon />
                            </IconButton>
                        </span>
                    </Tooltip>

                    <Menu
                        id="settings-menu"
                        anchorEl={settingsMenuAnchor}
                        open={settingsMenuOpen}
                        onClose={e => setSettingsMenuAnchor(null)}
                        sx={{ background: "transparent" }}>
                        <div style={{ paddingLeft: "10px" }}>
                            <Typography variant="button" display="block" gutterBottom >
                                Midi Settings
                            </Typography>
                            <FormControl style={{ margin: "8px" }}>
                                <InputLabel id="midi-input-label">MIDI Input</InputLabel>
                                <Select
                                    label="MIDI Device"
                                    value={(currentInput?.id ?? "") as unknown as { value: unknown }}
                                    onChange={onInputChanged}
                                    style={{ width: "200px" }}>
                                    {availableInputs.map(input =>
                                        <MenuItem key={'select-input-' + input.id} value={input.id}>{input.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <Typography variant="button" display="block" gutterBottom >
                                Midi Output
                            </Typography>
                            <FormGroup>
                                <FormControlLabel control={<Switch defaultChecked />} label="Computer" />
                                <FormControlLabel control={<Switch defaultChecked />} label="Piano" />
                            </FormGroup>
                        </div>
                    </Menu>

                    <Menu
                        id="volume-menu"
                        anchorEl={volumeMenuAnchor}
                        open={volumeMenuOpen}
                        onClose={e => setVolumeMenuAnchor(null)}
                        sx={{ background: "transparent" }}>
                        <Box
                            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                            style={{ marginRight: "32px", marginLeft: "16px", marginTop: "32px" }}
                        >

                            <Tooltip title={isMuted ? "Unmute" : "Mute"}>
                                <IconButton onClick={e => setIsMuted(!isMuted)}>
                                    {
                                        isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />
                                    }
                                </IconButton>
                            </Tooltip>
                            <Slider
                                area-label="Volume"
                                valueLabelDisplay="auto"
                                max={100}
                                min={0}
                                onChange={onVolumeChanged}
                                value={volume}
                                style={{ width: "200px" }}
                            />
                        </Box>
                    </Menu>
                </Box>

            </Toolbar>
            {
                !cursorDisabled ?
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <Slider
                            area-label="Measure"
                            valueLabelDisplay="auto"
                            disabled={cursorDisabled}
                            marks
                            max={maxMeasures}
                            onChange={onMeasureSliderChange}
                            value={currentMeasure}
                            style={{ margin: "2px", maxWidth: "80%", justifyContent: 'center', alignItems: 'center' }} />
                    </Box>
                    : <></>
            }

        </AppBar>
    )
}