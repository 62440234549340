export function waitMillis(millis: number) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('resolved');
      }, millis);
    });
}

export async function performAfterDelay(delayMillis: number, action: () => void) {
    await waitMillis(delayMillis)
    action()
}